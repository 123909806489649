import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Moonshot from './pages/moonshot/Moonshot';
import Pumps from './pages/pumps/Pumps';
import Tokens from './pages/tokens/Tokens';
import Token from './pages/token/Token';
import Home from './pages/home/Home';

import Analysis from './pages/analysis/Analysis';
import AnalysisRaydium from './pages/analysis/AnalysisRaydium';
import AnalysisPumpNew from './pages/analysis/AnalysisPumpNew';
import AnalysisPumpKing from './pages/analysis/AnalysisPumpKing';
import AnalysisGame from './pages/analysis/AnalysisGame';

import ToolSQL from './pages/tool-api/ToolSQL';
import ToolApi from './pages/tool-api/ToolApi';

import ToolCheck from './pages/tool-check/ToolCheck';
import CheckTxOwner from './pages/tool-check/TxOwner';
import CheckTx from './pages/tool-check/Tx';
import CheckTxBlock from './pages/tool-check/TxBlock';
import CheckToken from './pages/tool-check/Token';
import RequestToken from './pages/tool-check/RequestToken';
import RayToken from './pages/tool-check/RayToken';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-SFB7S88PVX');
// import ScrollToTop from 'react-scroll-to-top';

function App() {
  //google analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);



  return (
    <div className="App">
      <Router>
          <Switch>
              <Route path="/" exact component={Home}/> 
              <Route path="/tokens" component={Tokens} />
              <Route path="/pump" component={Pumps} />
              <Route path="/moonshot" component={Moonshot} />

              <Route path="/token/:mint_address" component={Token} />

              <Route path="/analysis" component={Analysis} />
              <Route path="/analysis-raydium" component={AnalysisRaydium} />
              <Route path="/analysis-pump-new" component={AnalysisPumpNew} />
              <Route path="/analysis-pump-king" component={AnalysisPumpKing} />
              <Route path="/analysis-game" component={AnalysisGame} />

              <Route path="/tool-api" component={ToolApi} />
              <Route path="/tool-sql" component={ToolSQL} />
              
              <Route path="/tool-check/tx-owner/:mint" component={CheckTxOwner} />
              <Route path="/tool-check/tx-owner" component={CheckTxOwner} />
              
              <Route path="/tool-check/req-tx/:transaction" component={CheckTx} />
              <Route path="/tool-check/req-tx" component={CheckTx} />
              
              <Route path="/tool-check/req-block/:mint" component={CheckTxBlock} />
              <Route path="/tool-check/req-block" component={CheckTxBlock} />

              <Route path="/tool-check/token/:mint" component={CheckToken} />
              <Route path="/tool-check/token" component={CheckToken} />

              <Route path="/tool-check/req-token/:mint" component={RequestToken} />
              <Route path="/tool-check/req-token" component={RequestToken} />
              
              <Route path="/tool-check/ray-token/:mint" component={RayToken} />
              <Route path="/tool-check/ray-token" component={RayToken} />
              
              <Route path="/tool-check/" component={ToolCheck} />

          </Switch>
      </Router>
    </div>
  );
  

}

export default App;
